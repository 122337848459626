import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salle-mscons/salle.msconsultations.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//

import filters from 'src/services/filters';
import { mapState } from 'vuex';
import date from 'quasar/src/utils/date.js';;
var getDateDiff = date.getDateDiff;
export default {
  props: {
    procedure: {
      type: Object,
      required: true
    }
  },
  computed: _objectSpread(_objectSpread({}, mapState('date', ['now'])), {}, {
    isDue: function isDue() {
      return getDateDiff(this.procedure.anticipatedEnd, this.now, 'minutes') < 0;
    }
  }),
  filters: filters
};
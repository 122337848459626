import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.find-index.js";
import Consultation from 'src/store/models/Consultation';
// import Exam from 'src/store/models/Exam'

export function ongoing() {
  return Consultation.query().where(function (consult) {
    return consult.isOngoing();
  }).get();
}
export function finished() {
  return Consultation.query().where(function (consult) {
    return consult.isFinished();
  }).get();
}
export function withExams() {
  return Consultation.query().has('exams').get();
}
export function withoutExam() {
  return Consultation.query().hasNot('exams').get();
}
export function usersHavingConsultations() {
  return Consultation.query().with('user.shifts').get().map(function (consult) {
    return consult.user;
  }).filter(function (v, i, a) {
    return a.findIndex(function (t) {
      return t.id === v.id;
    }) === i;
  }) // Unique
  .sort(function (a, b) {
    return a.shifts.length && b.shifts.length ? a.shifts[0].type.id - b.shifts[0].type.id : a.id - b.id;
  });
}
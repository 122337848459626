import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salle-mscons/salle.msconsultations.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es7.promise.finally.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.promise.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import SingleBase from "./SingleBase";
import SinceDuration from "../partials/SinceDuration";
import date from 'quasar/src/utils/date.js';;
var getDateDiff = date.getDateDiff;
export default {
  mixins: [SingleBase],
  data: function data() {
    return {
      loading: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('date', ['now'])), mapGetters('entities/exams', ['ongoing'])), {}, {
    exam: function exam() {
      var _this = this;
      return this.ongoing.find(function (exam) {
        return exam.type === _this.type && exam.visit_id === _this.visit_id;
      });
    },
    progression: function progression() {
      var expected = getDateDiff(this.exam.estimatedEndAt, this.exam.started_at, 'minutes');
      var duration = getDateDiff(this.now, this.exam.started_at, 'minutes');
      return Math.min(100, Math.floor(duration * 100 / expected));
    },
    due: function due() {
      return this.progression === 100;
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions({
    stop: 'entities/exams/stop'
  })), {}, {
    action: function action() {
      var _this2 = this;
      this.loading = true;
      return this.stop({
        id: this.exam.id
      }).finally(function () {
        _this2.loading = stop;
      });
    }
  }),
  components: {
    SinceDuration: SinceDuration
  }
};
import "core-js/modules/es6.object.assign.js";
import "core-js/modules/es6.function.name.js";
export function stateAssign(state, assign) {
  Object.assign(state, assign);
}
export function settings(state, settings) {
  state.settings = settings;
}
export function setShiftsVisibility(state, array) {
  state.ui.visibilities.shifts = array;
}
export function toggleOption(state, option) {
  state.user.options[option] = !state.user.options[option];
}
export function setConfig(state, payload) {
  state.settings.practice[payload.name] = payload.value;
}
export function toggleTcVisibility(state) {
  state.ui.visibilities.tc = !state.ui.visibilities.tc;
}
export function toggleFinishedVisibility(state) {
  state.ui.visibilities.finished = !state.ui.visibilities.finished;
}
export function toggleForm(state, id) {
  state.ui.openExitForm = id;
}
export function togglePractice(state, practice) {
  state.user.practice = practice;
}
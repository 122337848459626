//
//
//
//
//
//
//
//
//

import filters from 'src/services/filters';
import VisitEditor from 'components/visits/history/VisitEditor';
export default {
  props: {
    visit: {
      type: Object,
      default: null
    },
    since: {
      type: String,
      required: true
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  filters: filters,
  components: {
    VisitEditor: VisitEditor
  }
};
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.regexp.split.js";
import store from 'src/store';
import date from 'quasar/src/utils/date.js';import format from 'quasar/src/utils/format.js';;
var formatDate = date.formatDate,
  getDateDiff = date.getDateDiff;
var pad = format.pad,
  _capitalize = format.capitalize;
export default {
  time: function time(value) {
    if (!value) {
      return '';
    }
    return formatDate(value, 'HH:mm');
  },
  duration: function duration(value) {
    var now = store.state.date.now;
    var diff = getDateDiff(now, value, 'minutes');
    if (diff < 0) {
      if (diff > -10) {
        return "Dans ".concat(diff, " minutes");
      }
      if (diff > -30) {
        return "Dans env. ".concat(-5 * Math.ceil(diff / 5), " min");
      }
      if (diff > -60) {
        return "Dans env. ".concat(-10 * Math.ceil(diff / 10), " min");
      }
      return 'Dans ' + Math.floor(-diff / 60) + 'h+';
    }
    if (diff >= 60) {
      var h = Math.floor(diff / 60);
      var m = pad(diff % 60, 2);
      return "Depuis ".concat(h, "h").concat(m);
    }
    if (diff > 1) {
      return "Depuis ".concat(diff, " min");
    }
    return 'A l\'instant';
  },
  estimation: function estimation(value) {
    var now = store.state.date.now;
    var diff = getDateDiff(now, value, 'minutes');
    if (diff > 60) {
      var h = Math.floor(diff / 60);
      var m = pad(diff % 60, 2);
      return "Retard de ".concat(h, "h").concat(10 * Math.ceil(m / 10));
    }
    if (diff > 10) {
      return "".concat(5 * Math.ceil(diff / 5), " min de +");
    }
    if (diff > 0) {
      return 'C\'est l\'heure';
    }
    if (diff > -10) {
      return 'Dans qq minutes';
    }
    if (diff > -30) {
      return "Dans ".concat(-5 * Math.ceil(diff / 5), " min");
    }
    if (diff > -60) {
      return "Dans ".concat(-10 * Math.ceil(diff / 10), " min");
    }
    return 'Dans ' + Math.floor(-diff / 60) + 'h+';
  },
  plural: function plural(value) {
    return Number(value) > 1 ? 's' : '';
  },
  method: function method(name) {
    var method = store.state.auth.settings.payment_methods.find(function (m) {
      return m.name === name;
    });
    if (method) {
      return method.label;
    }
    return name;
  },
  pmCount: function pmCount(value) {
    if ('count' in value) {
      return ' #' + value.count;
    }
    return '';
  },
  amount: function amount(value) {
    return isNaN(value) || Number(value) === 0 ? '' : Number(value).toFixed(2);
  },
  seven: function seven(value) {
    return value.split('').map(function (int) {
      if (Number(int) === 7) {
        return "<span class=\"seven\">".concat(int, "</span>");
      }
      return int;
    }).join('');
  },
  capitalize: function capitalize(value) {
    return _capitalize(value);
  },
  truncate: function truncate(value) {
    var max = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 15;
    var suffix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '...';
    return value.length > max + 3 ? value.substr(0, max) + suffix : value;
  },
  nbFormat: function nbFormat(value) {
    return new Intl.NumberFormat().format(value.toFixed(0));
  }
};
import LocalStorage from 'quasar/src/plugins/LocalStorage.js';;
export default function () {
  return {
    user: LocalStorage.getItem('user') || {},
    settings: LocalStorage.getItem('settings') || {
      practice: {},
      payment_methods: [],
      quotes: []
    },
    ui: LocalStorage.getItem('ui') || {
      visibilities: {
        shifts: [],
        tc: true,
        finished: true
      },
      openExitForm: 0
    }
  };
}
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salle-mscons/salle.msconsultations.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.number.constructor.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Maths from "./Maths";
import User from 'src/store/models/User';
import filters from 'src/services/filters';
var plural = filters.plural;
export default {
  mixins: [Maths],
  props: {
    doctor: {
      type: Object,
      required: true
    }
  },
  computed: _objectSpread(_objectSpread({}, mapGetters({
    total: 'entities/visits/billed'
  })), {}, {
    user: function user() {
      return new User(this.doctor);
    },
    billed: function billed() {
      var _this = this;
      return this.total.filter(function (v) {
        if (v.doctor.id !== _this.doctor.id) {
          return false;
        }
        if (_this.doctor.shift.type.id < 30) {
          return new Date(v.finished_at).getTime() < _this.doctor.shift.end_at.getTime();
        }
        return new Date(v.finished_at).getTime() >= _this.doctor.shift.start_at.getTime();
      });
    },
    stat: function stat() {
      return {
        value: this.turnover,
        suffix: '€',
        desc: this.doctor.fullname,
        underline: "".concat(this.billed.length, " patient").concat(plural(this.billed.length)),
        subhead: this.doctor.shift.isSubstitute() ? this.commission + '€' : false,
        subs: [{
          value: Number(this.cashSum) || 'Aucune',
          suffix: this.cashCount ? '€' : '',
          desc: this.cashCount ? "".concat(this.cashCount, " esp\xE8ces") : 'Espèces'
        }, {
          value: Number(this.ccSum) || 'Aucune',
          suffix: this.ccCount ? '€' : '',
          desc: this.ccCount ? "".concat(this.ccCount, " CB").concat(plural(this.ccCount)) : 'CB'
        }, {
          value: Number(this.bqSum) || 'Aucun',
          suffix: this.bqCount ? '€' : '',
          desc: this.bqCount ? "".concat(this.bqCount, " ch\xE8que").concat(plural(this.bqCount)) : 'Chèque'
        }, {
          value: Number(this.wireSum) || 'Aucun',
          suffix: this.wireCount ? '€' : '',
          desc: 'Virement' + plural(this.wireCount)
        }, {
          value: this.unpaidCount ? this.unpaidSum : 'Aucun',
          suffix: this.unpaidCount ? '€' : '',
          desc: this.unpaidCount ? "".concat(this.unpaidCount, " impay\xE9").concat(plural(this.unpaidCount)) : 'Impayé',
          negative: !!this.unpaidCount
        }]
      };
    }
  }),
  filters: filters

  // components: {
  //     FinanceCard
  // }
};
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salle-mscons/salle.msconsultations.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es7.promise.finally.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.promise.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import filters from 'src/services/filters';
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('auth', ['user', 'ui'])), mapGetters('entities/shifts', ['ongoingShifts', 'todaysMedicalShifts', 'assistant'
  // 'doctors'
  ])), {}, {
    authAvatar: function authAvatar() {
      return process.env.API + this.user.avatar;
    },
    authCanSwitch: function authCanSwitch() {
      return this.assistant.id && this.user.id !== this.assistant.id;
    },
    shiftVisibility: function shiftVisibility() {
      var _this = this;
      return this.todaysMedicalShifts.reduce(function (acc, shift) {
        acc[shift.id] = _this.ongoingShifts.some(function (some) {
          return some.id === shift.id;
        }) ? 'fal fa-eye' : 'fal fa-eye-slash';
        return acc;
      }, []);
    },
    shiftVisibilityColor: function shiftVisibilityColor() {
      var _this2 = this;
      return function (id) {
        return _this2.ui.visibilities.shifts.some(function (shift) {
          return shift.id === id;
        }) ? 'primary' : 'grey-6';
      };
    }
  }),
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions('auth', [
  // 'toggleOption',
  'switchUser', 'doLogout'])), mapActions('entities/shifts', ['toggleShiftVisibility'])), {}, {
    logout: function logout() {
      var _this3 = this;
      this.doLogout().finally(function () {
        _this3.$router.push('/login');
      });
    }
  }),
  filters: filters
};
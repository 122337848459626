import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salle-mscons/salle.msconsultations.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapMutations } from 'vuex';
import SingleVisit from "./Single.vue";
import Toolbar from 'components/partials/Toolbar';
import scroll from 'quasar/src/utils/scroll.js';;
var getScrollTarget = scroll.getScrollTarget,
  setVerticalScrollPosition = scroll.setVerticalScrollPosition;
export default {
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState({
    index: function index(state) {
      return state.entities.visits.index;
    },
    tcVisibility: function tcVisibility(state) {
      return state.auth.ui.visibilities.tc;
    }
    // finishedVisibility: state => state.auth.ui.visibilities.finished
  })), mapGetters('entities/visits', ['isUnordered', 'query', 'comingAppointments'])), {}, {
    inLine: function inLine() {
      var _this = this;
      return this.index.filter(function (id) {
        return !_this.comingAppointments.some(function (v) {
          return v.id === id;
        });
      }).map(function (id) {
        return _this.query().with('consultations.exams').where('id', id).first();
      });
    } // hiddenCount () {
    //     return this.index.length - this.visibles.length
    // }
  }),
  methods: _objectSpread(_objectSpread({}, mapMutations('auth', ['toggleTcVisibility'
  // 'toggleFinishedVisibility'
  ])), {}, {
    tcVisibilityAction: function tcVisibilityAction() {
      var _this2 = this;
      this.toggleTcVisibility();
      return !this.tcVisibility || this.$nextTick(function () {
        var target = getScrollTarget(_this2.$refs.appointments.$el);
        var offset = _this2.$refs.appointments.$el.offsetTop - 350;
        setVerticalScrollPosition(target, offset, 150);
      });
    } // finishedVisibilityAction () {
    //     this.toggleFinishedVisibility()
    //     return !this.finishedVisibility || this.$nextTick(() => {
    //         const target = getScrollTarget(this.$refs.finished.$el)
    //         const offset = this.$refs.finished.$el.offsetTop - 350
    //         setVerticalScrollPosition(target, offset, 150)
    //     })
    // }
  }),
  components: {
    Toolbar: Toolbar,
    SingleVisit: SingleVisit
  }
};
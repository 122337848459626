import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _toConsumableArray from "/home/salle-mscons/salle.msconsultations.fr/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js";
import _defineProperty from "/home/salle-mscons/salle.msconsultations.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es6.regexp.replace.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.slice.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Maths from 'components/finances/Maths';
import { mapGetters, mapState, mapActions } from 'vuex';
// import AppSelect from 'src/mixins/app/Select'
// import FinancesTd from 'components/finances/Td'
import DocsFinances from 'components/finances/DocsFinances';
import FinancesTable from 'components/finances/Table';
// import FinanceCard from 'components/partials/Card'
// import AppTooltip from 'src/mixins/app/Tooltip'
import date from 'quasar/src/utils/date.js';import format from 'quasar/src/utils/format.js';;
import filters from 'src/services/filters';
var formatDate = date.formatDate;
var capitalize = format.capitalize;
export default {
  name: 'Finances',
  props: {
    timemachine: {
      type: Boolean,
      default: false
    }
  },
  mixins: [Maths],
  data: function data() {
    return {
      formatDate: formatDate,
      capitalize: capitalize,
      progression: 50
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState({
    now: function now(s) {
      return s.date.now;
    },
    float: function float(s) {
      return s.auth.settings.practice.float;
    },
    snp: function snp(s) {
      return s.auth.settings.snp;
    }
  })), mapGetters({
    all: 'entities/visits/all',
    billed: 'entities/visits/billed',
    // assistant: 'entities/shifts/assistant',
    todaysShifts: 'entities/shifts/todaysMedicalShifts',
    pastShifts: 'entities/shifts/pastShifts',
    consultations: 'entities/consultations/query',
    hasInconsistencies: 'entities/visits/hasInconsistencies',
    usersHavingConsultations: 'entities/consultations/usersHavingConsultations'
  })), {}, {
    humanReadableDate: function humanReadableDate() {
      return formatDate(this.now, 'dddd D MMMM YYYY').replace(' 1 ', ' 1er ');
    },
    patients: function patients() {
      return this.billed.length + ' /' + this.all().length;
    },
    patientsGone: function patientsGone() {
      return this.billed.filter(function (v) {
        return v.payment.method === 'gone';
      }).length;
    },
    // inconsistencies () {
    //     return this.billed.filter(v => v.inconsistency !== false).length
    // },
    cashSumWithFloat: function cashSumWithFloat() {
      return (Number(this.cashSum) + Number(this.float)).toFixed(2);
    },
    permanent: function permanent() {
      var shift = this.todaysShifts.find(function (s) {
        return s.type.id === 10;
      });
      return shift ? shift.user : null;
    },
    assistant: function assistant() {
      var shift = this.pastShifts.find(function (shift) {
        return shift.isAssistant();
      });
      return shift ? shift.user : {
        avatarUrl: 'https://ucarecdn.com/afbed8c0-db48-4571-af0f-da29ad375023/',
        fullname: 'Personne'
      };
    },
    shifts: function shifts() {
      return this.pastShifts.filter(function (s) {
        return !s.isAssistant();
      });
    },
    // usersHavingConsultations () {
    //     return this.consultations().with('user.shift').get()
    //         .map(c => c.user)
    //         .filter((user, i, arr) => arr.findIndex(u => u.id === user.id) === i)
    // },
    snpStats: function snpStats() {
      var _this = this;
      if (!this.permanent) return [];
      var users = this.snp.find(function (user) {
        return user.id === _this.permanent.id;
      }) ? this.snp : [this.permanent].concat(_toConsumableArray(this.snp));
      if (!users.length) return [];
      return users.map(function (user) {
        var _user$count;
        var count = user.id === _this.permanent.id ? ((_user$count = user.count) !== null && _user$count !== void 0 ? _user$count : 0) + _this.billed.reduce(function (acc, v) {
          var _v$quote$includes, _v$quote;
          return acc + Number((_v$quote$includes = (_v$quote = v.quote) === null || _v$quote === void 0 ? void 0 : _v$quote.includes('snp')) !== null && _v$quote$includes !== void 0 ? _v$quote$includes : 0);
        }, 0) : user.count;
        return _objectSpread(_objectSpread({}, user), {}, {
          color: _this.snpColor(count),
          count: count
        });
      });
    },
    users: function users() {
      return this.usersHavingConsultations.reduce(function (acc, user) {
        if (user.shifts.length > 1) {
          user.shifts.sort(function (a, b) {
            return a.end_at.getTime() - b.end_at.getTime();
          }).map(function (shift) {
            acc.push(_objectSpread(_objectSpread({}, user), {}, {
              shift: shift
            }));
          });
        } else {
          user.shift = user.shifts[0];
          acc.push(user);
        }
        return acc;
      }, []);
    } // dayStats hift() {
    //     return [hift
    //         {
    //             subs2: [
    //                 {
    //                     value: this.patientsGone ? this.patientsGone : 'Aucun',
    //                     suffix: '',
    //                     desc: `Parti${plural(this.patientsGone)} du service`
    //                 }
    //             ]
    //         }, {
    //             value: this.turnover,
    //             suffix: '€',
    //             desc: 'Chiffre d\'affaire',
    //             subs: [
    //                 {
    //                     value: Number(this.ccSum) ? this.ccSum : 'Aucune',
    //                     suffix: Number(this.ccSum) ? '€' : '',
    //                     desc: this.ccPatients
    //                         ? `${this.ccCount} CB${plural(this.ccCount)} (${this.ccPatients} patient${plural(this.ccPatients)})`
    //                         : 'Carte bancaire'
    //                 }, {
    //                     value: this.wireSum,
    //                     suffix: '€',
    //                     desc: 'Virements'
    //                 }, {
    //                     value: Number(this.bqSum) ? this.bqSum : 'Aucun',
    //                     suffix: Number(this.bqSum) ? '€' : '',
    //                     desc: this.bqPatients
    //                         ? `${this.bqCount} chèque${plural(this.bqCount)} (pour ${this.bqPatients} patient${plural(this.bqPatients)})`
    //                         : 'Chèque'
    //                 }
    //             ],
    //             subs2: [
    //                 {
    //                     value: 'float'
    //                 }, {
    //                     value: this.cashSumWithFloat,
    //                     suffix: '€',
    //                     desc: `${this.cashCount} en espèces${Number(this.float) ? ' (+<abbr title="Fond de Caisse">fc</abbr>)' : ''}`
    //                 }, {
    //                     value: this.unpaidCount ? this.unpaidSum : 'Aucun',
    //                     suffix: this.unpaidCount ? '€' : '',
    //                     desc: this.unpaidCount ? `${this.unpaidCount} impayé${plural(this.unpaidCount)}` : 'Impayé'
    //                 }
    //             ]
    //         }
    //     ]
    // }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions('auth', ['updateSettings'])), {}, {
    updateFloat: function updateFloat(value) {
      return this.updateSettings({
        float: value
      });
    },
    hexToRgb: function hexToRgb(hex) {
      var r = parseInt(hex.slice(1, 3), 16);
      var g = parseInt(hex.slice(3, 5), 16);
      var b = parseInt(hex.slice(5, 7), 16);
      return {
        r: r,
        g: g,
        b: b
      };
    },
    interpolateColor: function interpolateColor(color1, color2, factor) {
      if (factor < 0) factor = 0;
      if (factor > 1) factor = 1;
      var result = {
        r: Math.round(color1.r + factor * (color2.r - color1.r)),
        g: Math.round(color1.g + factor * (color2.g - color1.g)),
        b: Math.round(color1.b + factor * (color2.b - color1.b))
      };
      return "rgb(".concat(result.r, ", ").concat(result.g, ", ").concat(result.b, ")");
    },
    snpColor: function snpColor(value) {
      var positive = this.hexToRgb('#2dce89');
      var accent = this.hexToRgb('#9c27b0');
      var warning = this.hexToRgb('#fb6340');
      var negative = this.hexToRgb('#f5365c');
      if (value <= 10) {
        // Interpoler entre positive (0) et accent (10)
        return this.interpolateColor(positive, accent, value / 10);
      } else if (value <= 15) {
        // Interpoler entre accent (10) et warning (15)
        return this.interpolateColor(accent, warning, (value - 10) / 5);
      } else {
        // Interpoler entre warning (15) et negative (20)
        return this.interpolateColor(warning, negative, (value - 15) / 5);
      }
    }
  }),
  filters: filters,
  components: {
    FinancesTable: FinancesTable,
    DocsFinances: DocsFinances
    // FinanceCard
  }
};
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salle-mscons/salle.msconsultations.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es7.array.includes.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapMutations } from 'vuex';
// import Visit from 'src/store/models/Visit'
import Maths from 'components/finances/Maths';
// import FinancesTr from 'components/finances/Tr'
import VisitHistory from 'components/visits/history/History';
import VisitForm from 'components/visits/form/Form.vue';
import filters from 'src/services/filters';
var amount = filters.amount,
  method = filters.method,
  pmCount = filters.pmCount,
  time = filters.time;
export default {
  name: 'Finances',
  mixins: [Maths],
  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      pagination: {
        sortBy: 'finished_at',
        page: 1,
        rowsPerPage: 0
      },
      columns: [{
        name: 'id',
        field: function field(v) {
          return v.id;
        }
      }, {
        name: 'patient',
        label: 'Patient',
        sortable: true,
        // field: v => v.patient,
        align: 'left'
      }, {
        name: 'history',
        label: 'Historique',
        sortable: true,
        // field: v => v.finished_at,
        align: 'right'
      }, {
        name: 'finished_at',
        label: 'Sortie',
        sortable: true,
        field: function field(v) {
          return time(v.finished_at);
        },
        align: 'left'
      }, {
        name: 'quote',
        label: 'Cotation',
        sortable: true,
        field: function field(v) {
          return v.quote;
        },
        align: 'left'
      }, {
        name: 'invoice',
        label: 'Facture',
        sortable: true,
        field: function field(v) {
          return amount(v.invoice);
        },
        align: 'right'
      }, {
        name: 'third',
        label: 'Tiers',
        sortable: true,
        field: function field(v) {
          return v.third;
        },
        align: 'right'
      }, {
        name: 'method',
        label: 'Mode',
        sortable: true,
        field: function field(v) {
          return method(v.payment.method) + pmCount(v.payment);
        },
        align: 'center'
      }, {
        name: 'note',
        label: 'Note',
        sortable: true,
        field: function field(v) {
          return 'misc' in v.notes && v.notes.misc.length > 15 ? "".concat(v.notes.misc.substring(0, 15), "...") : (v.notes || {}).misc;
        },
        align: 'left'
      }, {
        name: 'wire',
        label: 'Virement',
        sortable: true,
        field: function field(v) {
          return amount(v.wire);
        },
        align: 'right'
      }, {
        name: 'desk',
        label: 'Caisse',
        sortable: true,
        field: function field(v) {
          return amount(v.desk);
        },
        align: 'right'
      }, {
        name: 'doctor',
        label: 'Médecin',
        sortable: true,
        field: function field(v) {
          return (v.doctor || {}).initials;
        },
        align: 'center'
      }, {
        //     name: 'inconsistency',
        //     sortable: false,
        //     style: 'max-width: 15px; padding: 0;',
        //     field: v => v.inconsistency,
        //     align: 'center'
        // }, {
        name: 'form',
        sortable: false,
        style: 'max-width: 15px; padding: 0;',
        field: function field(v) {
          return v.id;
        },
        align: 'center'
      }],
      // visibleColumns: [
      //     'patient',
      //     'finished_at',
      //     'quote',
      //     'invoice',
      //     'third',
      //     'method',
      //     'note',
      //     'wire',
      //     'desk',
      //     'doctor',
      //     'inconsistency',
      //     'form'
      // ],
      selection: [],
      filter: ''
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState({
    isAdmin: function isAdmin(s) {
      return s.auth.user.is_admin;
    },
    expanded: function expanded(s) {
      return s.auth.ui.openExitForm;
    },
    float: function float(s) {
      return s.auth.settings.practice.float;
    }
  })), mapGetters({
    rows: 'entities/visits/billed',
    hasInconsistencies: 'entities/visits/hasInconsistencies'
    // doctors: 'entities/shifts/doctorsWithConsultations'
  })), {}, {
    visibleColumns: function visibleColumns() {
      var columns = this.columns.map(function (c) {
        return c.name;
      }).filter(function (c) {
        return c !== 'id';
      });
      return !this.readonly || this.isAdmin ? columns : columns.filter(function (c) {
        return !['form'].includes(c);
      });

      // return [
      //     'patient',
      //     'finished_at',
      //     'quote',
      //     'invoice',
      //     'third',
      //     'method',
      //     'note',
      //     'wire',
      //     'desk',
      //     'doctor',
      //     // 'inconsistency',
      //     // 'form'
      // ]
    }
    /* doctorsSelection: {
        get () {
            return this.selection.length
                ? this.selection
                : this.doctors
        },
        set (value) {
            this.selection = value
        }
    } */
  }),

  methods: mapMutations('auth', ['toggleForm']),
  filters: filters,
  components: {
    // FinancesTr,
    VisitForm: VisitForm,
    VisitHistory: VisitHistory
  }
};